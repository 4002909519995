<template>
  <div class="my-5 pt-5">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="text-center my-5">
            <img
              src="@/assets/images/pesquisa/final-pesquisa.png"
              alt="Obrigado por participar da Pesquisa de Satisfação"
              class="img-responsive mb-5"
              style="max-width: 50%; height: auto"
            />
            <h3 class="text-uppercase">Agradecemos sua participação!</h3>
            <h6>Sua opinião é muito importante para nós.</h6>
            <div class="mt-3 text-center">
              <a
                class="btn btn-danger waves-effect waves-light"
                href="https://www.goidigital.com.br"
                >Acesse nosso site</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FinalDaPesquisa',
}
</script>
