<template>
  <Layout>
    <div class="row">
      <div class="col-sm-6">
        <PageHeader :title="title" />
      </div>
    </div>
    <br />
    <LoadingData v-if="loadData" />
    <FiltroResultadosPesquisa />
    <div class="card-body"></div>
  </Layout>
</template>

<script>
import Layout from '@/views/layouts/Layout.vue'
import PageHeader from '@/components/commons/PageHeader.vue'
import LoadingData from '@/components/commons/LoadingData.vue'
import FiltroResultadosPesquisa from '@/components/pesquisaDeSatisfacao/resultados/FiltroResultadosPesquisa.vue'

export default {
  name: 'ResultadosPesquisa',

  components: {
    Layout,
    PageHeader,
    LoadingData,
    FiltroResultadosPesquisa,
  },

  data() {
    return {
      title: 'Resultados de Pesquisa de Satisfação',
      loadData: false,
    }
  },
}
</script>
