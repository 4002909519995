<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <CreateEditFormularioDePesquisa :tipoPesquisaId="tipoPesquisaId" />
  </Layout>
</template>

<script>
import Layout from '@/views/layouts/Layout.vue'
import PageHeader from '@/components/commons/PageHeader.vue'
import CreateEditFormularioDePesquisa from '@/components/pesquisaDeSatisfacao/tiposPesquisas/CreateEditFormularioDePesquisa.vue'

export default {
  name: 'FormularioDePesquisa',

  components: {
    Layout,
    PageHeader,
    CreateEditFormularioDePesquisa,
  },

  data() {
    return {
      tipoPesquisaId: this.$route.params.tipoPesquisaId,
      title: 'Formulário de Pesquisa de Satisfação',
      items: [
        {
          text: 'Pesquisa de Satisfação',
        },
        {
          text: 'Tipos de Pesquisa',
          to: '/pesquisa-satisfacao/tipo-pesquisa',
        },
        {
          text: 'Formulário',
          active: true,
        },
      ],
    }
  },
}
</script>
