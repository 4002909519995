<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <CreateEditPerguntaModal
      v-if="showCreateEditPerguntaModal"
      :perguntaId="perguntaIdSelected"
      @closeModal="closeCreateEditPerguntaModal"
    />
    <LoadingData v-if="loadData" />
    <div v-else class="card">
      <div
        class="card-header d-flex justify-content-between align-items-center bg-transparent border-bottom"
      >
        <h5 class="card-title mb-0">Perguntas</h5>
        <div v-if="getModuleUserByCode('4.01.002')">
          <button
            type="button"
            class="btn btn-primary"
            title="Novo Pergunta"
            @click="createEditPergunta()"
          >
            <i class="ri-add-fill align-middle"></i>Nova Pergunta
          </button>
        </div>
      </div>
      <div class="card-body">
        <ListPerguntas
          :perguntas="perguntas"
          @editPergunta="createEditPergunta"
        />
      </div>
    </div>
  </Layout>
</template>

<script>
import { mapGetters } from 'vuex'
import Layout from '@/views/layouts/Layout.vue'
import PageHeader from '@/components/commons/PageHeader.vue'
import LoadingData from '@/components/commons/LoadingData.vue'
import CreateEditPerguntaModal from '@/components/pesquisaDeSatisfacao/perguntas/CreateEditPerguntaModal.vue'
import ListPerguntas from '@/components/pesquisaDeSatisfacao/perguntas/ListPerguntas.vue'

export default {
  name: 'Perguntas',

  components: {
    Layout,
    PageHeader,
    CreateEditPerguntaModal,
    LoadingData,
    ListPerguntas,
  },

  data() {
    return {
      title: 'Perguntas',
      items: [
        {
          text: 'BackOffice',
        },
        {
          text: 'Perguntas',
          active: true,
        },
      ],
      loadData: true,
      showCreateEditPerguntaModal: false,
      perguntas: null,
      perguntaIdSelected: null,
    }
  },

  computed: {
    ...mapGetters(['getModuleUserByCode']),
  },

  methods: {
    async getPerguntasPesquisas() {
      try {
        this.perguntas = await this.$store.dispatch('getPerguntasPesquisas')
        this.loadData = false
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
        }
      }
    },

    createEditPergunta(perguntaId) {
      this.perguntaIdSelected = perguntaId || null
      this.showCreateEditPerguntaModal = true
    },

    closeCreateEditPerguntaModal(loadListPerguntas) {
      if (loadListPerguntas) {
        this.getPerguntasPesquisas()
      }
      this.showCreateEditPerguntaModal = false
    },
  },

  mounted() {
    this.getPerguntasPesquisas()
  },
}
</script>
