<template>
  <div>
    <loading-data v-if="loadData" />
    <div v-else class="card">
      <div
        class="card-header d-flex justify-content-between align-items-center bg-transparent border-bottom"
      >
        <h5 class="card-title mb-0">
          Formulário: {{ tipo_pesquisa.descricao }}
        </h5>
        <button
          type="button"
          class="btn btn-primary"
          title="Novo Pergunta"
          @click="save"
        >
          <i class="ri-check align-middle"></i>Salvar
        </button>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-8">
            <multiselect
              v-model="perguntaSelecionada"
              :options="perguntas"
              track-by="id"
              :custom-label="nameWithLang"
              placeholder="Selecione uma pergunta para adicionar ao formulário"
              :selectLabel="'Clique para selecionar'"
              :deselectLabel="'Clique para tirar seleção'"
              :selectedLabel="'Selecionado'"
            ></multiselect>
          </div>
          <div class="col-md-4">
            <button
              type="button"
              @click="addPergunta"
              class="btn btn-primary"
              title="Adicionar pergunta ao formulário"
              :disabled="!perguntaSelecionada"
            >
              <i class="bx ri-add-line"></i> Adicionar pergunta
            </button>
          </div>
        </div>
        <hr />
        <div class="row">
          <div class="col-md-12">
            <draggable
              class="list-group"
              group="tasks"
              :list="perguntasSelecionadas"
            >
              <div
                v-for="(item, index) in perguntasSelecionadas"
                :key="index"
                class="card task-box"
              >
                <div
                  class="progress progress-sm animated-progess"
                  style="height: 3px"
                >
                  <div
                    class="progress-bar bg-info"
                    role="progressbar"
                    style="width: 100%"
                    aria-valuenow="100"
                    aria-valuemin="0"
                    aria-valuemax="100"
                    variant="success"
                  ></div>
                </div>
                <b-card no-body class="mb-1 shadow-none">
                  <b-card-header header-tag="header" role="tab">
                    <div class="row">
                      <div class="col-sm-1 d-flex align-items-center">
                        <br />
                        <a
                          v-b-toggle="'accordion-' + index"
                          class="btn btn-lg btn-outline-info btn-rounded"
                          href="javascript: void(0);"
                          >{{ index + 1 }}</a
                        >
                      </div>

                      <div class="col-sm-10 d-flex align-items-center">
                        <h6>{{ item.descricao }}</h6>
                      </div>
                      <div class="col-sm-1 d-flex align-items-center">
                        <br />
                        <button
                          type="button"
                          @click="deletePergunta(index)"
                          class="btn btn-lg btn-outline-danger"
                          title="Adicionar item"
                        >
                          <i class="bx ri-delete-bin-line"></i>
                        </button>
                      </div>
                    </div>
                  </b-card-header>
                  <b-collapse
                    :id="'accordion-' + index"
                    visible
                    accordion="my-accordion"
                    role="tabpanel"
                  ></b-collapse>
                  <b-collapse
                    :id="'accordion-' + index"
                    visible
                    accordion="my-accordion"
                    role="tabpanel"
                  >
                  </b-collapse>
                </b-card>
              </div>
            </draggable>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { statusTranslate } from '@/utils/strings'
import LoadingData from '@/components/commons/LoadingData.vue'
import draggable from 'vuedraggable'
import Multiselect from 'vue-multiselect'

export default {
  name: 'CreateEditFormularioDePesquisa',

  components: {
    LoadingData,
    draggable,
    Multiselect,
  },

  props: {
    tipoPesquisaId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      loadData: true,
      perguntas: {},
      perguntasSelecionadas: [],
      tipo_pesquisa: {},
      statusTranslate,
      perguntaSelecionada: null,
    }
  },

  methods: {
    nameWithLang({ id, descricao }) {
      return `${id} - ${descricao}`
    },

    addPergunta() {
      if (this.perguntaSelecionada) {
        this.perguntasSelecionadas.push(this.perguntaSelecionada)
        this.perguntaSelecionada = null
      }
    },

    deletePergunta(index) {
      this.perguntasSelecionadas.splice(index, 1)
    },

    async getPerguntasByTipoPesquisa() {
      try {
        this.perguntasSelecionadas = await this.$store.dispatch(
          'getPerguntasByTipoPesquisa',
          this.tipoPesquisaId,
        )
        this.loadData = false
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
        }
      }
    },

    async getPerguntasPesquisas() {
      try {
        this.perguntas = await this.$store.dispatch('getPerguntasPesquisas')
        this.loadData = false
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
        }
      }
    },

    async getTipoPesquisa() {
      try {
        this.tipo_pesquisa = await this.$store.dispatch(
          'getTipoPesquisa',
          this.tipoPesquisaId,
        )
        this.loadData = false
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
        }
      }
    },

    async save() {
      try {
        let response = null
        response = await this.$store.dispatch('createEditFormularioPesquisa', {
          id: this.tipoPesquisaId,
          perguntasSelecionadas: this.perguntasSelecionadas,
        })
        this.$root.$bvToast.toast(response.message, {
          title: 'Sucesso!',
          variant: 'success',
          solid: true,
        })
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
        }
      }
    },
  },

  mounted() {
    this.getPerguntasByTipoPesquisa()
    this.getTipoPesquisa()
    this.getPerguntasPesquisas()
  },
}
</script>
