<template>
  <b-modal
    id="modalCreateEditPergunta"
    size="xl"
    :title="textModal"
    title-class="font-18"
    hide-footer
    no-close-on-backdrop
    @hidden="$emit('closeModal', false)"
  >
    <form @submit.prevent="save">
      <div class="col-lg-12">
        <b-tabs
          justified
          nav-class="nav-tabs-custom"
          content-class="p-3 text-muted"
        >
          <b-tab active>
            <template v-slot:title>
              <span class="d-inline-block d-sm-none">
                <i class="fas fa-home"></i>
              </span>
              <span class="d-none d-sm-inline-block">Pergunta</span>
            </template>

            <!-- NOME | TIPO DE CLIENTE | DATA DE INICIO -->
            <div class="form-row">
              <div class="form-group col-sm-3">
                <label>Tipo: <span class="text-danger">*</span></label>
                <select
                  class="form-control"
                  :class="{
                    'is-invalid': $v.pergunta.tipo.$error,
                  }"
                  v-model="$v.pergunta.tipo.$model"
                >
                  <option value="">Selecione o tipo de pergunta</option>
                  <option value="1">Resposta Curta</option>
                  <option value="2">Parágrafo</option>
                  <option value="3">Seleção Única</option>
                  <option value="4">Seleção Múltipla</option>
                  <option value="5">Data</option>
                  <option value="6">NPS</option>
                  <option value="7">CSAT</option>
                </select>
                <span
                  v-if="!$v.pergunta.tipo.required"
                  class="invalid-feedback"
                >
                  O campo é obrigatório
                </span>
              </div>
              <div class="form-group col-sm-9">
                <label>Descrição: <span class="text-danger">*</span></label>
                <input
                  type="text"
                  class="form-control"
                  :class="{ 'is-invalid': $v.pergunta.descricao.$error }"
                  placeholder="Descrição da pergunta"
                  v-model="$v.pergunta.descricao.$model"
                />
                <span
                  v-if="!$v.pergunta.descricao.required"
                  class="invalid-feedback"
                >
                  O campo é obrigatório
                </span>
                <span
                  v-if="!$v.pergunta.descricao.minLength"
                  class="invalid-feedback"
                >
                  O nome deve possuir pelo menos 8 caracteres
                </span>
              </div>
            </div>

            <!-- EMAIL E CELULAR -->
            <div class="form-row">
              <div class="form-group col-sm-2">
                <p>Obrigatória: <span class="text-danger">*</span></p>
                <div class="form-check 6-check-inline">
                  <input
                    type="radio"
                    class="form-check-input"
                    id="obrigatoriaInativo"
                    value="0"
                    v-model="pergunta.obrigatoria"
                  />
                  <label class="form-check-label" for="obrigatoriaInativo">
                    Não
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    type="radio"
                    class="form-check-input"
                    id="obrigatoriaAtivo"
                    value="1"
                    v-model="pergunta.obrigatoria"
                  />
                  <label class="form-check-label" for="obrigatoriaAtivo">
                    Sim
                  </label>
                </div>
              </div>
              <div
                v-if="
                  pergunta.tipo == 1 ||
                  pergunta.tipo == 2 ||
                  pergunta.tipo == 3 ||
                  pergunta.tipo == 4 ||
                  pergunta.tipo == 5
                "
                class="form-group col-sm-2"
              >
                <p>Observação: <span class="text-danger">*</span></p>
                <div class="form-check 6-check-inline">
                  <input
                    type="radio"
                    class="form-check-input"
                    id="observacaoNao"
                    value="0"
                    v-model="pergunta.observacao"
                  />
                  <label class="form-check-label" for="observacaoNao">
                    Não
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    type="radio"
                    class="form-check-input"
                    id="observacaoSim"
                    value="1"
                    v-model="pergunta.observacao"
                  />
                  <label class="form-check-label" for="observacaoSim">
                    Sim
                  </label>
                </div>
              </div>
              <div
                v-if="pergunta.observacao == 1 && pergunta.tipo != 7"
                class="form-group col-sm-8"
              >
                <label>Descrição da observação:</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Descrição da Observação"
                  v-model="pergunta.descricao_observacao"
                />
              </div>
            </div>
            <div class="form-row">
              <div v-if="perguntaId" class="form-group col-sm-6">
                <p>Status: <span class="text-danger">*</span></p>
                <div class="form-check 6-check-inline">
                  <input
                    type="radio"
                    class="form-check-input"
                    id="statusInativo"
                    value="0"
                    v-model="pergunta.status"
                  />
                  <label class="form-check-label" for="statusInativo">
                    Inativo
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    type="radio"
                    class="form-check-input"
                    id="statusAtivo"
                    value="1"
                    v-model="pergunta.status"
                  />
                  <label class="form-check-label" for="statusAtivo">
                    Ativo
                  </label>
                </div>
              </div>
            </div>
          </b-tab>
          <b-tab v-if="pergunta.tipo == 3 || pergunta.tipo == 4">
            <template v-slot:title>
              <span class="d-inline-block d-sm-none">
                <i class="far fa-user"></i>
              </span>
              <span class="d-none d-sm-inline-block">Alternativas</span>
            </template>
            <div role="tablist">
              <draggable class="list-group" group="tasks" :list="alternativas">
                <div
                  v-for="(item, index) in alternativas"
                  :key="index"
                  class="card task-box"
                >
                  <div
                    class="progress progress-sm animated-progess"
                    style="height: 3px"
                  >
                    <div
                      class="progress-bar bg-info"
                      role="progressbar"
                      style="width: 100%"
                      aria-valuenow="100"
                      aria-valuemin="0"
                      aria-valuemax="100"
                      variant="success"
                    ></div>
                  </div>
                  <b-card no-body class="mb-1 shadow-none">
                    <b-card-header header-tag="header" role="tab">
                      <div class="form-row">
                        <div class="form-group col-sm-2">
                          <br />
                          <a
                            v-b-toggle="'accordion-' + index"
                            class="btn btn-lg btn-outline-info btn-rounded"
                            href="javascript: void(0);"
                            >{{ index + 1 }}</a
                          >
                        </div>

                        <div class="form-group col-sm-9">
                          <label>Descrição da alternativa:</label>
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Digite a descrição da alternativa"
                            :name="'alternativas[' + index + '].[descricao]'"
                            :id="'alternativas[' + index + ']'"
                            v-model="alternativas[index].descricao"
                            autocomplete="off"
                          />
                        </div>
                        <div class="form-group col-sm-1">
                          <br />
                          <button
                            type="button"
                            @click="deleteAlternativa(index)"
                            class="btn btn-lg btn-outline-danger"
                            title="Adicionar item"
                          >
                            <i class="bx ri-delete-bin-line"></i>
                          </button>
                        </div>
                      </div>
                    </b-card-header>
                    <b-collapse
                      :id="'accordion-' + index"
                      visible
                      accordion="my-accordion"
                      role="tabpanel"
                    ></b-collapse>
                    <b-collapse
                      :id="'accordion-' + index"
                      visible
                      accordion="my-accordion"
                      role="tabpanel"
                    >
                    </b-collapse>
                  </b-card>
                </div>
              </draggable>
              <div>
                <button
                  type="button"
                  @click="addAlternativa()"
                  class="btn btn-primary"
                  title="Adicionar rasgadinha"
                >
                  <i class="bx ri-add-line"></i> Adicionar alternativa
                </button>
              </div>
            </div>
          </b-tab>
          <b-tab v-if="pergunta.tipo == 7">
            <template v-slot:title>
              <span class="d-inline-block d-sm-none">
                <i class="far fa-user"></i>
              </span>
              <span class="d-none d-sm-inline-block">Alternativas</span>
            </template>
            <div role="tablist">
              <div
                v-for="(item, index) in alternativasCsat"
                :key="index"
                class="form-row"
              >
                <div class="form-group col-sm-2">
                  <span class="btn btn-lg btn-outline-info btn-rounded">{{
                    index + 1
                  }}</span>
                </div>
                <div class="form-group col-sm-10">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Digite a descrição da alternativa"
                    :name="'alternativasCsat[0].[descricao]'"
                    :id="'alternativasCsat[0]'"
                    v-model="item.descricao"
                    autocomplete="off"
                  />
                </div>
              </div>
            </div>
          </b-tab>
        </b-tabs>
      </div>
      <hr />
      <div class="modal-footer-custom">
        <button
          type="button"
          class="btn btn-secondary"
          title="Cancelar"
          @click="$emit('closeModal', false)"
        >
          Cancelar
        </button>
        <button
          type="submit"
          class="btn btn-primary"
          :title="textModal"
          :disabled="$v.$invalid || disableButton"
        >
          <i
            v-if="disableButton"
            class="spinner-grow spinner-grow-custom text-wither"
          />
          <span v-else>{{ textModal }}</span>
        </button>
      </div>
    </form>
  </b-modal>
</template>

<script>
import { required, minLength } from 'vuelidate/lib/validators'
import draggable from 'vuedraggable'

export default {
  name: 'CreateEditPerguntaModal',

  components: { draggable },

  props: {
    perguntaId: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      pergunta: {
        descricao: '',
        tipo: '',
        observacao: 0,
        descricao_observacao: '',
        obrigatoria: 1,
      },
      alternativas: [],
      alternativasCsat: [
        { descricao: 'Muito Insatisfeito', peso: '1' },
        { descricao: 'Insatisfeito', peso: '2' },
        { descricao: 'Indiferente', peso: '3' },
        { descricao: 'Satisfeito', peso: '4' },
        { descricao: 'Muito Satisfeito', peso: '5' },
      ],
      disableButton: false,
      loadDataEditing: false,
    }
  },

  computed: {
    textModal() {
      return this.perguntaId ? 'Atualizar' : 'Cadastrar'
    },
  },

  methods: {
    addAlternativa() {
      this.alternativas.push({
        descricao: '',
        peso: '',
      })
    },
    deleteAlternativa(index) {
      this.alternativas.splice(index, 1)
    },

    async getPergunta() {
      try {
        this.loadDataEditing = true
        this.pergunta = await this.$store.dispatch(
          'getPergunta',
          this.perguntaId,
        )
        this.alternativas = await this.$store.dispatch(
          'getAlternativasByPergunta',
          this.perguntaId,
        )
        this.$bvModal.show('modalCreateEditPergunta')
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
        }
        this.$emit('closeModal', false)
      }
    },

    async save() {
      if (!this.$v.$invalid) {
        this.disableButton = true
        try {
          let response = null
          if (
            this.pergunta.tipo === '1' ||
            this.pergunta.tipo === '2' ||
            this.pergunta.tipo === '5' ||
            this.pergunta.tipo === '6'
          ) {
            this.alternativas = []
          } else if (this.pergunta.tipo === '7') {
            this.alternativas = this.alternativasCsat
          }
          if (this.perguntaId) {
            response = await this.$store.dispatch('updatePergunta', {
              id: this.perguntaId,
              pergunta: this.pergunta,
              alternativas: this.alternativas,
            })
          } else {
            response = await this.$store.dispatch('savePerguntaPesquisa', {
              pergunta: this.pergunta,
              alternativas: this.alternativas,
            })
          }
          this.disableButton = false
          this.$root.$bvToast.toast(response.message, {
            title: 'Sucesso!',
            variant: 'success',
            solid: true,
          })
          this.$emit('closeModal', true)
        } catch (error) {
          this.disableButton = false
          if (error && error.message) {
            this.$root.$bvToast.toast(error.message, {
              title: 'Atenção!',
              variant: 'warning',
              solid: true,
            })
          } else {
            this.$root.$bvToast.toast(
              'Não foi possível se conectar ao servidor.',
              {
                title: 'Erro!',
                variant: 'danger',
                solid: true,
              },
            )
          }
        }
      }
    },
  },

  validations: {
    pergunta: {
      descricao: {
        required,
        minLength: minLength(8),
      },
      tipo: { required },
      observacao: { required },
    },
  },

  mounted() {
    if (this.perguntaId) {
      this.getPergunta()
    } else {
      this.$bvModal.show('modalCreateEditPergunta')
    }
  },
}
</script>
