<template>
  <b-modal
    id="modalCreateEditTipoPesquisa"
    size="xl"
    :title="textModal"
    title-class="font-18"
    hide-footer
    no-close-on-backdrop
    @hidden="$emit('closeModal', false)"
  >
    <form @submit.prevent="save">
      <div class="form-row">
        <div class="form-group col-sm-12">
          <label>Descrição: <span class="text-danger">*</span></label>
          <input
            type="text"
            class="form-control"
            :class="{ 'is-invalid': $v.tipo_pesquisa.descricao.$error }"
            placeholder="Descrição do tipo de pesquisa"
            v-model="$v.tipo_pesquisa.descricao.$model"
          />
          <span
            v-if="!$v.tipo_pesquisa.descricao.required"
            class="invalid-feedback"
          >
            O campo é obrigatório
          </span>
          <span
            v-if="!$v.tipo_pesquisa.descricao.minLength"
            class="invalid-feedback"
          >
            A descrição deve possuir pelo menos 3 caracteres
          </span>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-sm-12">
          <label>Texto de Apresentação:</label>
          <textarea
            class="form-control"
            placeholder="Descreva um texto para ser exibido no formulário de pesquisa"
            v-model="tipo_pesquisa.texto"
          ></textarea>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-sm-3">
          <p>
            Solicitar Filial na pesquisa: <span class="text-danger">*</span>
          </p>
          <div class="form-check form-check-inline">
            <input
              type="radio"
              class="form-check-input"
              id="solicitarFilialNao"
              value="0"
              v-model="tipo_pesquisa.solicitar_filial"
            />
            <label class="form-check-label" for="solicitarFilialNao">
              Não
            </label>
          </div>
          <div class="form-check form-check-inline">
            <input
              type="radio"
              class="form-check-input"
              id="solicitarFilialSim"
              value="1"
              v-model="tipo_pesquisa.solicitar_filial"
            />
            <label class="form-check-label" for="solicitarFilialSim">
              Sim
            </label>
          </div>
        </div>
        <div
          v-if="tipo_pesquisa.solicitar_filial == 1"
          class="form-group col-sm-9"
        >
          <label
            >Texto da opção de filial: <span class="text-danger">*</span></label
          >
          <input
            type="text"
            class="form-control"
            placeholder="Texto da opção de filial no formulário"
            v-model="tipo_pesquisa.texto_opcao_filial"
            :required="tipo_pesquisa.solicitar_filial == 1"
          />
        </div>
      </div>
      <div v-if="tipoPesquisaId" class="form-row">
        <div class="form-group col-sm-6">
          <p>Status: <span class="text-danger">*</span></p>
          <div class="form-check form-check-inline">
            <input
              type="radio"
              class="form-check-input"
              id="statusInativo"
              value="0"
              v-model="tipo_pesquisa.status"
            />
            <label class="form-check-label" for="statusInativo">
              Inativo
            </label>
          </div>
          <div class="form-check form-check-inline">
            <input
              type="radio"
              class="form-check-input"
              id="statusAtivo"
              value="1"
              v-model="tipo_pesquisa.status"
            />
            <label class="form-check-label" for="statusAtivo"> Ativo </label>
          </div>
        </div>
      </div>
      <hr />
      <div class="modal-footer-custom">
        <button
          type="button"
          class="btn btn-secondary"
          title="Cancelar"
          @click="$emit('closeModal', false)"
        >
          Cancelar
        </button>
        <button
          type="submit"
          class="btn btn-primary"
          :title="textModal"
          :disabled="$v.$invalid || disableButton"
        >
          <i
            v-if="disableButton"
            class="spinner-grow spinner-grow-custom text-wither"
          />
          <span v-else>{{ textModal }}</span>
        </button>
      </div>
    </form>
  </b-modal>
</template>

<script>
import { required, minLength } from 'vuelidate/lib/validators'

export default {
  name: 'CreateEditTiposPesquisasModal',
  props: {
    tipoPesquisaId: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      tipo_pesquisa: {
        descricao: '',
        texto: '',
        solicitar_filial: 0,
        texto_opcao_filial: '',
      },
      disableButton: false,
    }
  },

  computed: {
    textModal() {
      return this.tipoPesquisaId ? 'Atualizar' : 'Cadastrar'
    },
  },

  methods: {
    async getTipoPesquisa() {
      try {
        this.tipo_pesquisa = await this.$store.dispatch(
          'getTipoPesquisa',
          this.tipoPesquisaId,
        )
        this.$bvModal.show('modalCreateEditTipoPesquisa')
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
        }
        this.$emit('closeModal', false)
      }
    },

    async save() {
      if (!this.$v.$invalid) {
        this.disableButton = true
        try {
          let response = null
          if (this.tipoPesquisaId) {
            response = await this.$store.dispatch('updateTipoPesquisa', {
              id: this.tipoPesquisaId,
              tipo_pesquisa: this.tipo_pesquisa,
            })
          } else {
            response = await this.$store.dispatch(
              'saveTipoPesquisa',
              this.tipo_pesquisa,
            )
          }
          this.disableButton = false
          this.$root.$bvToast.toast(response.message, {
            title: 'Sucesso!',
            variant: 'success',
            solid: true,
          })
          this.$emit('closeModal', true)
        } catch (error) {
          this.disableButton = false
          if (error && error.message) {
            this.$root.$bvToast.toast(error.message, {
              title: 'Atenção!',
              variant: 'warning',
              solid: true,
            })
          } else {
            this.$root.$bvToast.toast(
              'Não foi possível se conectar ao servidor.',
              {
                title: 'Erro!',
                variant: 'danger',
                solid: true,
              },
            )
          }
        }
      }
    },
  },

  validations: {
    tipo_pesquisa: {
      descricao: {
        required,
        minLength: minLength(3),
      },
    },
  },

  mounted() {
    if (this.tipoPesquisaId) {
      this.getTipoPesquisa()
    } else {
      this.$bvModal.show('modalCreateEditTipoPesquisa')
    }
  },
}
</script>
