<template>
  <div>
    <PageHeader />
    <div class="card">
      <div
        style="display: flex; justify-content: center"
        class="card-header align-items-center bg-transparent border-bottom"
      >
        <img
          style="width: 200px; text-align: center"
          src="@/assets/images/logo/logo-dark.png"
        />
      </div>
    </div>
    <div class="card">
      <div
        class="card-header d-flex justify-content-between align-items-center bg-transparent border-bottom"
      ></div>
      <div class="card-body">
        <ListPerguntasParaResponder :tipoPesquisaId="tipoPesquisaId" />
      </div>
      <div
        class="card-footer d-flex justify-content-between align-items-center bg-transparent border-bottom"
      >
        Desenvolvidor por Go I Assessoria Digital e Desenvolvimento
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from '@/components/commons/PageHeader.vue'
import ListPerguntasParaResponder from '@/components/pesquisaDeSatisfacao/respostasPesquisas/ListPerguntasParaResponder.vue'

export default {
  name: 'RespostaPesquisa',

  components: {
    PageHeader,
    ListPerguntasParaResponder,
  },

  data() {
    return {
      tipoPesquisaId: this.$route.params.tipoPesquisaId,
    }
  },
}
</script>
