<template>
  <div>
    <loading-data v-if="loadData" />
    <div v-else class="card">
      <div class="card-header align-items-center bg-transparent border-bottom">
        <h5 class="card-title">
          {{ tipo_pesquisa.descricao }}
        </h5>
        <p class="card-title-desc">{{ tipo_pesquisa.texto }}</p>
      </div>
      <div class="card-body">
        <form @submit.prevent="save">
          <label
            >* Caso queira se identificar preencha os campos abaixo antes de
            responder a pesquisa.</label
          >
          <div class="form-row">
            <div class="form-group col-sm-6">
              <label>Nome:</label>
              <input
                type="text"
                class="form-control"
                placeholder="Seu nome"
                v-model="pesquisa.nome"
              />
            </div>
            <div class="form-group col-sm-6">
              <label>Empresa:</label>
              <input
                type="text"
                class="form-control"
                placeholder="Nome da empresa"
                v-model="pesquisa.empresa"
              />
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-sm-6">
              <label>Celular:</label>
              <input
                type="text"
                class="form-control"
                placeholder="nº do celular"
                v-model="pesquisa.telefone"
              />
            </div>
            <div class="form-group col-sm-6">
              <label>E-mail:</label>
              <input
                type="email"
                class="form-control"
                placeholder="Seu melhor e-mail"
                v-model="pesquisa.email"
              />
            </div>
          </div>
          <hr />
          <div
            v-if="tipo_pesquisa.solicitar_filial == 1"
            class="form-row mb-3 ml-4"
          >
            <div class="form-group col-sm-6">
              <h5>
                {{ tipo_pesquisa.texto_opcao_filial }}
                <span class="text-danger">*</span>
              </h5>
              <select
                class="form-control"
                v-model="pesquisa.unidade_id"
                :class="{
                  'is-invalid':
                    tipo_pesquisa.texto_opcao_filial == 1 &&
                    pesquisa.unidade_id == '' &&
                    submitted == true,
                }"
                :required="tipo_pesquisa.solicitar_filial == 1"
              >
                <option value="">Selecione uma opção</option>
                <option
                  v-for="unidade in unidades"
                  :key="unidade.id"
                  :value="unidade.id"
                  :title="unidade.descricao"
                >
                  {{ unidade.descricao }}
                </option>
              </select>
              <span
                v-if="
                  tipo_pesquisa.texto_opcao_filial == 1 &&
                  pesquisa.unidade_id == '' &&
                  submitted == true
                "
                class="invalid-feedback"
              >
                O campo é obrigatório
              </span>
            </div>
          </div>
          <div class="mb-3" v-for="(pergunta, index) in perguntas" :key="index">
            <div class="col-sm-12 d-flex align-items-center">
              <label class="btn btn-sm btn-outline-success btn-rounded">
                {{ index + 1 }}
              </label>
              &nbsp;&nbsp;
              <h6>
                &nbsp;{{ pergunta.descricao }}
                <span class="text-danger" v-if="pergunta.obrigatoria == 1"
                  >*</span
                >
              </h6>
            </div>
            <div v-if="pergunta.tipo == 1">
              <div class="form-row ml-4 mr-5">
                <div class="col-12">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Descreva sua resposta"
                    v-model="respostas[index]['resposta']"
                    :class="{
                      'is-invalid':
                        respostas[index].obrigatoria == 1 &&
                        respostas[index].resposta.length == 0 &&
                        submitted == true,
                    }"
                  />
                  <span
                    v-if="
                      respostas[index].obrigatoria == 1 &&
                      respostas[index].resposta.length == 0 &&
                      submitted == true
                    "
                    class="invalid-feedback"
                  >
                    Pergunta obrigatória
                  </span>
                </div>
              </div>
              <div
                v-if="pergunta.observacao == 1"
                class="form-row mt-3 ml-4 mr-5"
              >
                <div class="col-12">
                  <label>{{ pergunta.descricao_observacao }}</label>
                  <input
                    type="text"
                    class="form-control"
                    :placeholder="pergunta.descricao_observacao"
                    v-model="respostas[index]['observacao']"
                    :class="{
                      'is-invalid':
                        respostas[index].obrigatoria == 1 &&
                        respostas[index].resposta.length == 0 &&
                        submitted == true,
                    }"
                  />
                  <span
                    v-if="
                      respostas[index].obrigatoria == 1 &&
                      respostas[index].resposta.length == 0 &&
                      submitted == true
                    "
                    class="invalid-feedback"
                  >
                    Pergunta obrigatória
                  </span>
                </div>
              </div>
            </div>
            <div v-if="pergunta.tipo == 2">
              <div class="form-row ml-4 mr-5">
                <div class="col-12">
                  <textarea
                    class="form-control"
                    placeholder="Descreva sua resposta"
                    v-model="respostas[index]['resposta']"
                    :class="{
                      'is-invalid':
                        respostas[index].obrigatoria == 1 &&
                        respostas[index].resposta.length == 0 &&
                        submitted == true,
                    }"
                  />
                  <span
                    v-if="
                      respostas[index].obrigatoria == 1 &&
                      respostas[index].resposta.length == 0 &&
                      submitted == true
                    "
                    class="invalid-feedback"
                  >
                    Pergunta obrigatória
                  </span>
                </div>
              </div>
            </div>
            <div v-if="pergunta.tipo == 3" class="row">
              <div class="col-md-12">
                <div>
                  <div
                    class="custom-control d-inline-block custom-radio mb-3 ml-4"
                    v-for="(alternativa, indexAlt) in pergunta.alternativas"
                    :key="indexAlt"
                  >
                    <input
                      type="radio"
                      :id="`alternativa_${index}_${indexAlt}_${pergunta.id}_${alternativa.id}`"
                      :name="`alternativa_${index}_${indexAlt}_${alternativa.id}`"
                      :value="alternativa.id"
                      v-model="respostas[index]['resposta']"
                      class="custom-control-input"
                      :class="{
                        'is-invalid':
                          respostas[index].obrigatoria == 1 &&
                          respostas[index].resposta.length == 0 &&
                          submitted == true,
                      }"
                    />
                    <span
                      v-if="
                        respostas[index].obrigatoria == 1 &&
                        respostas[index].resposta.length == 0 &&
                        submitted == true
                      "
                      class="invalid-feedback"
                    >
                      Pergunta obrigatória
                    </span>
                    <label
                      class="custom-control-label"
                      :for="`alternativa_${index}_${indexAlt}_${pergunta.id}_${alternativa.id}`"
                      >{{ alternativa.descricao }}</label
                    >
                  </div>
                </div>
              </div>
            </div>
            <div v-if="pergunta.tipo == 4" class="row">
              <div class="col-md-12">
                <div
                  v-for="(alternativa, indexAlt) in pergunta.alternativas"
                  :key="indexAlt"
                  class="mb-3 ml-4"
                >
                  <div class="custom-control custom-checkbox mb-3">
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      :id="`alternativa_${index}_${indexAlt}_${pergunta.id}_${alternativa.id}`"
                      :value="alternativa.id"
                      :checked="
                        respostas[index]['resposta'].includes(alternativa.id)
                      "
                      @change="atualizarRespostas(index, alternativa.id)"
                      :class="{
                        'is-invalid':
                          respostas[index].obrigatoria == 1 &&
                          respostas[index].resposta.length == 0 &&
                          submitted == true,
                      }"
                    />
                    <label
                      class="custom-control-label"
                      :for="`alternativa_${index}_${indexAlt}_${pergunta.id}_${alternativa.id}`"
                      >{{ alternativa.descricao }}</label
                    >
                  </div>
                </div>
                <span
                  v-if="
                    respostas[index].obrigatoria == 1 &&
                    respostas[index].resposta.length == 0 &&
                    submitted == true
                  "
                  class="invalid-feedback"
                >
                  Pergunta obrigatória
                </span>
              </div>
            </div>
            <div v-if="pergunta.tipo == 5">
              <div class="form-row ml-4 mr-5">
                <div class="col-6">
                  <input
                    type="date"
                    class="form-control"
                    placeholder="Descreva sua resposta"
                    v-model="respostas[index]['resposta']"
                    :class="{
                      'is-invalid':
                        respostas[index].obrigatoria == 1 &&
                        respostas[index].resposta.length == 0 &&
                        submitted == true,
                    }"
                  />
                  <span
                    v-if="
                      respostas[index].obrigatoria == 1 &&
                      respostas[index].resposta.length == 0 &&
                      submitted == true
                    "
                    class="invalid-feedback"
                  >
                    Pergunta obrigatória
                  </span>
                </div>
              </div>
            </div>
            <div v-if="pergunta.tipo == 6">
              <div class="form-row">
                <div
                  class="form-row btn-group-toggle mt-2 ml-4 mt-xl-0 d-flex justify-content-center flex-wrap"
                  data-toggle="buttons"
                >
                  <label
                    class="btn btn-danger btn-lg mr-1 mb-2"
                    :class="{
                      active: respostas[index]['resposta'] === '1',
                    }"
                  >
                    <input
                      id="option2"
                      type="radio"
                      name="options"
                      value="1"
                      v-model="respostas[index]['resposta']"
                    />
                    1
                  </label>
                  <label
                    class="btn btn-danger btn-lg mr-1 mb-2"
                    :class="{
                      active: respostas[index]['resposta'] === '2',
                    }"
                  >
                    <input
                      id="option3"
                      type="radio"
                      name="options"
                      value="2"
                      v-model="respostas[index]['resposta']"
                    />
                    2
                  </label>
                  <label
                    class="btn btn-danger btn-lg mr-1 mb-2"
                    :class="{
                      active: respostas[index]['resposta'] === '3',
                    }"
                  >
                    <input
                      id="option3"
                      type="radio"
                      name="options"
                      value="3"
                      v-model="respostas[index]['resposta']"
                    />
                    3
                  </label>
                  <label
                    class="btn btn-danger btn-lg mr-1 mb-2"
                    :class="{
                      active: respostas[index]['resposta'] === '4',
                    }"
                  >
                    <input
                      id="option3"
                      type="radio"
                      name="options"
                      value="4"
                      v-model="respostas[index]['resposta']"
                    />
                    4
                  </label>
                  <label
                    class="btn btn-danger btn-lg mr-1 mb-2"
                    :class="{
                      active: respostas[index]['resposta'] === '5',
                    }"
                  >
                    <input
                      id="option3"
                      type="radio"
                      name="options"
                      value="5"
                      v-model="respostas[index]['resposta']"
                    />
                    5
                  </label>
                  <label
                    class="btn btn-danger btn-lg mr-1 mb-2"
                    :class="{
                      active: respostas[index]['resposta'] === '6',
                    }"
                  >
                    <input
                      id="option3"
                      type="radio"
                      name="options"
                      value="6"
                      v-model="respostas[index]['resposta']"
                    />
                    6
                  </label>
                  <label
                    class="btn btn-warning btn-lg mr-1 mb-2"
                    :class="{
                      active: respostas[index]['resposta'] === '7',
                    }"
                  >
                    <input
                      id="option3"
                      type="radio"
                      name="options"
                      value="7"
                      v-model="respostas[index]['resposta']"
                    />
                    7
                  </label>
                  <label
                    class="btn btn-warning btn-lg mr-1 mb-2"
                    :class="{
                      active: respostas[index]['resposta'] === '8',
                    }"
                  >
                    <input
                      id="option3"
                      type="radio"
                      name="options"
                      value="8"
                      v-model="respostas[index]['resposta']"
                    />
                    8
                  </label>
                  <label
                    class="btn btn-success btn-lg mr-1 mb-2"
                    :class="{
                      active: respostas[index]['resposta'] === '9',
                    }"
                  >
                    <input
                      id="option3"
                      type="radio"
                      name="options"
                      value="9"
                      v-model="respostas[index]['resposta']"
                    />
                    9
                  </label>
                  <label
                    class="btn btn-success btn-lg mr-1 mb-2"
                    :class="{
                      active: respostas[index]['resposta'] === '10',
                    }"
                  >
                    <input
                      id="option3"
                      type="radio"
                      name="options"
                      value="10"
                      v-model="respostas[index]['resposta']"
                    />
                    10
                  </label>
                </div>
              </div>
              <span
                v-if="
                  respostas[index].obrigatoria == 1 &&
                  respostas[index].resposta.length == 0 &&
                  submitted == true
                "
                class="text-danger ml-4"
              >
                Pergunta obrigatória
              </span>
            </div>
            <div v-if="pergunta.tipo == 7" class="mt-2 ml-4 mt-xl-0">
              <div class="form-row">
                <div class="d-flex">
                  <label class="radio-label">
                    <input
                      type="radio"
                      class="hidden-radio"
                      :id="`alternativa_${index}_0_${pergunta.id}_${pergunta.alternativas[0].id}`"
                      :name="`alternativa_${index}_0_${pergunta.alternativas[0].id}`"
                      :value="pergunta.alternativas[0].id"
                      v-model="respostas[index]['resposta']"
                    />
                    <div class="image-circle">
                      <img
                        class="img-responsive custom-image"
                        src="@/assets/images/pesquisa/1.png"
                        style="max-width: 100%"
                      />
                    </div>
                  </label>
                  <label class="radio-label">
                    <input
                      type="radio"
                      class="hidden-radio"
                      :id="`alternativa_${index}_1_${pergunta.id}_${pergunta.alternativas[1].id}`"
                      :name="`alternativa_${index}_1_${pergunta.alternativas[1].id}`"
                      :value="pergunta.alternativas[1].id"
                      v-model="respostas[index]['resposta']"
                    />
                    <div class="image-circle">
                      <img
                        class="img-responsive custom-image"
                        src="@/assets/images/pesquisa/2.png"
                        style="max-width: 100%"
                      />
                    </div>
                  </label>
                  <label class="radio-label">
                    <input
                      type="radio"
                      class="hidden-radio"
                      :id="`alternativa_${index}_2_${pergunta.id}_${pergunta.alternativas[2].id}`"
                      :name="`alternativa_${index}_2_${pergunta.alternativas[2].id}`"
                      :value="pergunta.alternativas[2].id"
                      v-model="respostas[index]['resposta']"
                    />
                    <div class="image-circle">
                      <img
                        class="img-responsive custom-image"
                        src="@/assets/images/pesquisa/3.png"
                        style="max-width: 100%"
                      />
                    </div>
                  </label>
                  <label class="radio-label">
                    <input
                      type="radio"
                      class="hidden-radio"
                      :id="`alternativa_${index}_3_${pergunta.id}_${pergunta.alternativas[3].id}`"
                      :name="`alternativa_${index}_3_${pergunta.alternativas[3].id}`"
                      :value="pergunta.alternativas[3].id"
                      v-model="respostas[index]['resposta']"
                    />
                    <div class="image-circle">
                      <img
                        class="img-responsive custom-image"
                        src="@/assets/images/pesquisa/4.png"
                        style="max-width: 100%"
                      />
                    </div>
                  </label>
                  <label class="radio-label">
                    <input
                      type="radio"
                      class="hidden-radio"
                      :id="`alternativa_${index}_4_${pergunta.id}_${pergunta.alternativas[4].id}`"
                      :name="`alternativa_${index}_4_${pergunta.alternativas[4].id}`"
                      :value="pergunta.alternativas[4].id"
                      v-model="respostas[index]['resposta']"
                    />
                    <div class="image-circle">
                      <img
                        class="img-responsive custom-image"
                        src="@/assets/images/pesquisa/5.png"
                        style="max-width: 100%"
                      />
                    </div>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div
            class="card-footer d-flex justify-content-between align-items-right bg-transparent border-bottom"
          >
            <button type="submit" class="btn btn-primary" title="Novo Pergunta">
              <i class="ri-send-plane-fill align-middle"></i>Enviar
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingData from '@/components/commons/LoadingData.vue'
import PATHS from '@/router/routesMap'

export default {
  name: 'CreateEditFormularioDePesquisa',

  components: {
    LoadingData,
  },

  props: {
    tipoPesquisaId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      loadData: true,
      perguntas: [],
      tipo_pesquisa: {},
      respostas: [],
      selecoesCheckbox: [],
      submitted: false,
      submitform: false,
      pesquisa: {
        nome: '',
        empresa: '',
        telefone: '',
        email: '',
        unidade_id: '',
      },
    }
  },

  methods: {
    isSelecionado(index, altIndex) {
      return this.selecionados[index] === altIndex
    },

    atualizarRespostas(index, alternativaId) {
      const selecionado = this.respostas[index].resposta.includes(alternativaId)
      if (selecionado) {
        const indexSelecionado =
          this.respostas[index].resposta.indexOf(alternativaId)
        this.respostas[index].resposta.splice(indexSelecionado, 1)
      } else {
        this.respostas[index].resposta.push(alternativaId)
      }
    },

    async getPerguntasByTipoPesquisa() {
      try {
        this.perguntas = await this.$store.dispatch(
          'getPerguntasParaResponder',
          this.tipoPesquisaId,
        )
        this.respostas = this.perguntas.map((pergunta) => ({
          perguntaId: pergunta.id,
          tipo: pergunta.tipo,
          obrigatoria: pergunta.obrigatoria,
          resposta: [],
          observacao: null,
        }))
        this.loadData = false
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
        }
      }
    },

    async getTipoPesquisa() {
      try {
        this.tipo_pesquisa = await this.$store.dispatch(
          'getTipoPesquisaFormularioDeResposta',
          this.tipoPesquisaId,
        )
        this.loadData = false
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
        }
      }
    },

    async getUnidades() {
      try {
        this.unidades = await this.$store.dispatch(
          'getUnidadesFormularioPesquisa',
        )
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
        }
      }
    },

    async save() {
      try {
        this.submitted = true
        let cont = 0
        // eslint-disable-next-line no-plusplus
        for (let index = 0; index < this.respostas.length; index++) {
          if (
            // eslint-disable-next-line eqeqeq
            this.respostas[index].obrigatoria == 1 &&
            // eslint-disable-next-line eqeqeq
            this.respostas[index].resposta.length == 0
          ) {
            cont += 1
          }
        }
        // eslint-disable-next-line eqeqeq
        if (cont == 0) {
          this.submitform = true
        } else {
          this.$root.$bvToast.toast(
            'Preencha todas as perguntas obrigatórias.',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
        }
        if (this.submitform === true) {
          let response = null
          response = await this.$store.dispatch('saveRespostaPesquisa', {
            tipo_pesquisa_id: this.tipoPesquisaId,
            respostas: this.respostas,
            pesquisa: this.pesquisa,
          })
          this.$root.$bvToast.toast(response.message, {
            title: 'Sucesso!',
            variant: 'success',
            solid: true,
          })
          this.$router.push(PATHS.FINAL_PESQUISA_SATISFACAO)
        }
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
        }
      }
    },
  },
  mounted() {
    this.getPerguntasByTipoPesquisa()
    this.getTipoPesquisa()
    this.getUnidades()
  },
}
</script>

<style>
.radio-label {
  position: relative;
}

.hidden-radio {
  opacity: 0;
  pointer-events: none;
}

.image-circle {
  display: inline-block;
  border: 2px solid transparent;
  border-radius: 50%;
  padding: 2px;
  transition: border-color 0.2s ease;
}

input[type='radio']:checked + .image-circle {
  border-color: blue;
}

.image-description {
  display: block;
  text-align: center;
  margin-top: 5px; /* Espaçamento entre a imagem e a descrição */
  word-wrap: break-word; /* Quebra de linha automática */
}

/* Adicione este CSS no seu arquivo de estilos ou dentro da tag <style> do componente Vue */
@media (min-width: 768px) {
  .custom-image {
    width: 45px; /* Defina aqui o tamanho que você deseja para as imagens em telas maiores */
  }
}
</style>
