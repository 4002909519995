<template>
  <div>
    <b-modal
      id="modalRespostaIndividual"
      size="xl"
      title="Respostas da Pesquisa"
      title-class="font-18"
      hide-footer
      no-close-on-backdrop
      @hidden="$emit('closeModal', false)"
    >
      <h5>Nome: {{ nome }} | Empresa: {{ empresa }}</h5>
      <p>{{ data }}</p>
      <hr />
      <div v-if="respostaPesquisaIndividual">
        <div v-for="item in respostaPesquisaIndividual.data" :key="item.id">
          <h6>{{ item.descricao }}</h6>
          <p>R: {{ item.resposta }}</p>
          <br v-if="item.observacao_pergunta == 1" />
          <h6 v-if="item.observacao_pergunta == 1">
            {{ item.descricao_observacao }}
          </h6>
          <p v-if="item.observacao_pergunta == 1 && item.observacao">
            {{ item.observacao_resposta }}
          </p>
          <br />
        </div>
      </div>
    </b-modal>
    <div class="card mx-auto">
      <div class="card mt-3 mb-3 mr-3 ml-3 p-3">
        <div class="form-row">
          <div class="form-group col-sm-3">
            <label>Tipo de Pesquisa:</label>
            <select
              class="form-control"
              v-model="tipoPesquisaId"
              @change="getTipoPesquisa"
            >
              <option value="">Selecione uma opção</option>
              <option
                v-for="pesquisa in tiposPesquisas"
                :key="pesquisa.id"
                :value="pesquisa.id"
                :title="`${pesquisa.id} - ${pesquisa.descricao}`"
              >
                {{ pesquisa.id }} - {{ pesquisa.descricao }}
              </option>
            </select>
          </div>
          <div
            v-if="tipoPesquisaId && tipoPesquisa.solicitar_filial == 1"
            class="form-group col-sm-3"
            style="width: 200px"
          >
            <label>Filial:</label>
            <multiselect
              v-model="unidadesSelecionadas"
              :options="unidades"
              :multiple="true"
              :close-on-select="false"
              :clear-on-select="false"
              :preserve-search="true"
              placeholder="Selecione as unidades"
              label="descricao"
              track-by="descricao"
              :preselect-first="true"
              :selectLabel="'Selecionado'"
            >
              <template v-if="unidadesSelecionadas.length > 1" slot="selection"
                >{{ unidadesSelecionadas.length }} filiais
                selecionadas</template
              >
            </multiselect>
          </div>
          <div class="form-group col-sm-2">
            <label>Data Início:</label>
            <input
              type="date"
              class="form-control"
              placeholder="Data Inicial"
              v-model="dataInicio"
            />
          </div>
          <div class="form-group col-sm-2">
            <label>Data Final:</label>
            <input
              type="date"
              class="form-control"
              placeholder="Data Final"
              v-model="dataFinal"
            />
          </div>
          <div class="form-group ml-3" style="display: flex; align-items: end">
            <button
              type="submit"
              class="btn btn-primary"
              title="Buscar"
              @click="getDadosResultado()"
            >
              <span>Buscar</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <LoadingData v-if="loadData" />
    <div v-if="showDashboard" class="card mx-auto">
      <div class="card mt-3 mb-3 mr-3 ml-3 p-3">
        <div
          class="card-header d-flex justify-content-between align-items-center bg-transparent border-bottom"
        >
          <h5 class="card-title mb-0">SATISFAÇÃO GERAL</h5>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-lg-4">
              <b-card
                header-class="bg-transparent border-danger"
                class="border border-danger"
              >
                <template v-slot:header>
                  <h5 class="my-0 text-danger">
                    <i class="mdi mdi-bullseye-arrow mr-3"></i>Insatisfação
                    Geral
                  </h5>
                </template>
                <h3 class="mt-0">
                  {{ satisfacaoGeral.data.insatisfeitos.percentual }}%
                </h3>
                <p class="card-text">
                  Porcentagens de pessoas que estão insatisfeitas com seus
                  serviços.
                </p>
              </b-card>
            </div>
            <div class="col-lg-4">
              <b-card
                header-class="bg-transparent border-warning"
                class="border border-warning"
              >
                <template v-slot:header>
                  <h5 class="my-0 text-warning">
                    <i class="mdi mdi-bullseye-arrow mr-1"></i>Indiferentes
                  </h5>
                </template>
                <h3 class="mt-0">
                  {{ satisfacaoGeral.data.indiferentes.percentual }}%
                </h3>
                <p class="card-text">
                  Porcentagens de pessoas que são indiferentes com a satisfação
                  dos seus serviços.
                </p>
              </b-card>
            </div>
            <div class="col-lg-4">
              <b-card
                header-class="bg-transparent border-success"
                class="border border-success"
              >
                <template v-slot:header>
                  <h5 class="my-0 text-success">
                    <i class="mdi mdi-bullseye-arrow mr-3"></i>Satisfação Geral
                  </h5>
                </template>
                <h3 class="mt-0">
                  {{ satisfacaoGeral.data.satisfeitos.percentual }}%
                </h3>
                <p class="card-text">
                  Porcentagens de pessoas que estão stisfeitas com os serviços.
                </p>
              </b-card>
            </div>
          </div>
          <div class="row justify-content-center">
            <apexchart
              class="apex-charts"
              height="320"
              type="pie"
              dir="ltr"
              :series="chartSeriesSatisfacaoGeral()"
              :options="chartOptionsSatisfacaoGeral"
            ></apexchart>
          </div>
        </div>
      </div>

      <!-- ITENS DE CSAT -->
      <div class="card mt-3 mb-3 mr-3 ml-3 p-3">
        <div
          class="card-header d-flex justify-content-between align-items-center bg-transparent border-bottom"
        >
          <h5 class="card-title mb-0">CSAT - CUSTOMER SATISFACTION SCORE</h5>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="table-responsive">
              <table class="table table-striped mb-0">
                <thead>
                  <tr>
                    <th>Pergunta</th>
                    <th class="text-center">Muito insatisfeito</th>
                    <th class="text-center">Insatisfeito</th>
                    <th class="text-center">Indiferente</th>
                    <th class="text-center">Satisfeito</th>
                    <th class="text-center">Muito Satisfeito</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="resposta in respostasCsat.data" :key="resposta.id">
                    <th scope="row">{{ resposta.descricao }}</th>
                    <td
                      class="text-center"
                      v-for="item in resposta.alternativas"
                      :key="item.id"
                    >
                      {{ item.resultado }}%
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <!-- NPS -->
      <div
        v-for="resposta in respostasNps.data"
        :key="resposta.id"
        class="card mt-3 mb-3 mr-3 ml-3 p-3"
      >
        <div
          class="card-header d-flex justify-content-between align-items-center bg-transparent border-bottom"
        >
          <h5 class="card-title mb-0">{{ resposta.descricao }}</h5>
        </div>
        <div class="card-body">
          <div class="row justify-content-center">
            <div
              v-for="item in resposta.alternativas"
              :key="item.valor_nota"
              class="col-md-1"
            >
              <b-card
                :bg-variant="getBgVariant(item.valor_nota)"
                class="text-white text-center"
              >
                <h3 class="mt-0 mb-4 text-white">
                  {{ item.valor_nota }}
                </h3>
                <hr />
                <p class="card-text">
                  {{ item.total_respostas }}
                </p>
              </b-card>
            </div>
          </div>
          <hr />
          <div class="row">
            <div class="col-lg-4">
              <b-card
                header-class="bg-transparent border-danger"
                class="border border-danger"
              >
                <template v-slot:header>
                  <h5 class="my-0 text-danger">
                    <i class="mdi mdi-bullseye-arrow mr-3"></i>Detratores
                  </h5>
                </template>
                <h3 class="mt-0">{{ resposta.detratores.percentual }}%</h3>
                <p class="card-text">
                  Necessitam de atenção! Não estão felizes com a sua empresa e
                  há a uma grande possibhidade de que falem mal dela por aí.
                </p>
              </b-card>
              <b-card
                header-class="bg-transparent border-warning"
                class="border border-warning"
              >
                <template v-slot:header>
                  <h5 class="my-0 text-warning">
                    <i class="mdi mdi-bullseye-arrow mr-1"></i>Passivos
                  </h5>
                </template>
                <h3 class="mt-0">{{ resposta.neutros.percentual }}%</h3>
                <p class="card-text">
                  Suscetíveis às ofertas da concorrência e suas avaliações não
                  são consideradas no cálculo de pontuação NPS.
                </p>
              </b-card>
              <b-card
                header-class="bg-transparent border-success"
                class="border border-success"
              >
                <template v-slot:header>
                  <h5 class="my-0 text-success">
                    <i class="mdi mdi-bullseye-arrow mr-3"></i>Promotores
                  </h5>
                </template>
                <h3 class="mt-0">{{ resposta.promotores.percentual }}%</h3>
                <p class="card-text">
                  São os possíveis embaixadores de marca! Gostaram tanto da
                  experiência que são leais e praticam o marketing boca-a-boca.
                </p>
              </b-card>
            </div>
            <div class="col-lg-8">
              <h2 class="text-center mt-5">NPS</h2>
              <h5 class="text-center">Net Promoter Score</h5>
              <h1
                class="text-center mt-3"
                :class="{
                  'text-success': resposta.nps_geral > 75,
                  'text-danger': resposta.nps_geral < 0,
                  'text-warning':
                    resposta.nps_geral >= 50 && resposta.nps_geral <= 75,
                }"
              >
                {{ resposta.nps_geral }}
              </h1>
              <div class="barra-lateral-success mt-5">
                <h5 class="text-success">
                  Zona de excelência: pontuação de 75 até 100
                </h5>
                <p>
                  Estar nessa zona de classificação é o sonho de toda empresa.
                  Alcançar essa faixa significa que o impacto sobre os clientes
                  é muito positivo e a probabilidade de tornarem-se embaixadores
                  da marca é muito alta.
                </p>
              </div>
              <div class="barra-lateral-success-2">
                <h5 class="text-success">
                  Zona de qualidade: pontuação de 50 até 75
                </h5>
                <p>
                  Ainda considerado um ótimo nps. Se sua pontuação NPS está
                  nessa zona, significa que o número de clientes promotores é
                  maior que a de detratores e você está no caminho certo.
                </p>
              </div>
              <div class="barra-lateral-warning">
                <h5 class="text-warning">
                  Zona de aperfeiçoamento: pontuação de 0 até 50
                </h5>
                <p>
                  Você está indo bem, mas ainda tem espaço para melhorar. Foque
                  em cada grupo de consumidor separadamente e ofereça o que cada
                  um deles precisa, recuperando os detratores e motivando os
                  passivos a tornarem-se promotores.
                </p>
              </div>
              <div class="barra-lateral-danger">
                <h5 class="text-danger">
                  Zona Crítica: pontuação de -100 até 0
                </h5>
                <p>
                  A zona crítica é delicada porque um alto número de clientes
                  detratores não significa apenas que a maioria não está
                  satisfeita, mas que provavelmente estão falando mal da empresa
                  e, ao compartilhar suas experiências negativas, influenciam
                  futuros compradores.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- ITENS DE SELEÇÃO ÚNICA -->
      <div
        v-for="resposta in respostasAlternativas.data"
        :key="resposta.id"
        class="card mt-3 mb-3 mr-3 ml-3 p-3"
      >
        <div
          class="card-header d-flex justify-content-between align-items-center bg-transparent border-bottom"
        >
          <h5 class="card-title mb-0">{{ resposta.descricao }}</h5>
        </div>
        <div class="row">
          <div class="col-lg-6">
            <div class="table-responsive">
              <table class="table table-striped mb-0">
                <thead>
                  <tr>
                    <th>Alternativa</th>
                    <th class="text-center">Total de resposta</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in resposta.alternativas" :key="item.id">
                    <td>{{ item.descricao }}</td>
                    <td class="text-center">{{ item.total_respostas }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="col-lg-6">
            <apexchart
              class="apex-charts"
              height="320"
              type="pie"
              dir="ltr"
              :series="chartSeries(resposta)"
              :options="chartOptions"
            ></apexchart>
          </div>
        </div>
      </div>

      <!-- ITENS DE paragrafos -->
      <div
        v-for="resposta in respostasParagrafo.data"
        :key="resposta.id"
        class="card mt-3 mb-3 mr-3 ml-3 p-3"
      >
        <div
          class="card-header d-flex justify-content-between align-items-center bg-transparent border-bottom"
        >
          <h5 class="card-title mb-0">{{ resposta.descricao }}</h5>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <div class="table-responsive">
              <table class="table table-striped mb-0">
                <thead>
                  <tr>
                    <th>Resposta</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in resposta.respostas" :key="item.id">
                    <td>{{ item.resposta }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <!-- RESPOSTAS INDIVIDUAIS -->
      <div class="card mt-3 mb-3 mr-3 ml-3 p-3">
        <div
          class="card-header d-flex justify-content-between align-items-center bg-transparent border-bottom"
        >
          <h5 class="card-title mb-0">Respostas Individuais</h5>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <div class="table-responsive mb-0">
              <b-table
                :items="respostasPesquisas.data"
                :fields="table.fields"
                responsive="sm"
                :per-page="table.perPage"
                :current-page="table.currentPage"
                :sort-by.sync="table.sortBy"
                :sort-desc.sync="table.sortDesc"
                :filter="table.filter"
                :filter-included-fields="table.filterOn"
                @filtered="onFiltered"
              >
                <template #cell(data)="row">
                  <span v-html="row.value"></span>
                </template>

                <template #cell(actions)="row">
                  <button
                    type="button"
                    class="btn btn-outline-warning rounded-pill"
                    title="Editar"
                    @click="getRespostaPesquisaById(row)"
                  >
                    <i class="ri-file-list-line align-middle"></i>
                  </button>
                </template>
              </b-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import moment from 'moment'
import { dateSqlToDateBR } from '@/utils/date'
import LoadingData from '@/components/commons/LoadingData.vue'

export default {
  name: 'FiltroResultadosPesquisa',

  components: {
    Multiselect,
    LoadingData,
  },

  data() {
    return {
      dataInicio: '',
      dataFinal: '',
      unidades: null,
      unidadesSelecionadas: [],
      tiposPesquisas: null,
      tipoPesquisaId: '',
      nome: '',
      empresa: '',
      data: '',
      tipoPesquisa: null,
      loadData: false,
      respostasAlternativas: null,
      respostasCsat: null,
      respostasNps: null,
      respostasParagrafo: null,
      satisfacaoGeral: null,
      respostasPesquisas: null,
      respostaPesquisaIndividual: null,
      showDashboard: false,
      table: {
        totalRows: 1,
        currentPage: 1,
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        filter: null,
        filterOn: [],
        sortBy: 'nome',
        sortDesc: false,
        fields: [
          { key: 'id', label: 'Cód.', sortable: true },
          { key: 'nome', label: 'Nome', sortable: true },
          { key: 'empresa', label: 'Empresa', sortable: true },
          { key: 'Telefone', label: 'Telefone', sortable: true },
          { key: 'email', label: 'E-mail', sortable: true },
          {
            key: 'data',
            label: 'Data',
            sortable: true,
            sortByFormatted: true,
            filterByFormatted: true,
            formatter: (value) => {
              return dateSqlToDateBR(value)
            },
          },
          {
            key: 'actions',
            label: 'Respostas',
            thStyle: { width: '130px' },
          },
        ],
      },
      chartOptions: {
        chart: {
          type: 'pie',
        },
        labels: [],
        legend: {
          position: 'bottom',
        },
      },
      chartOptionsSatisfacaoGeral: {
        chart: {
          type: 'pie',
        },
        labels: ['Satisfeitos', 'Indiferentes', 'Insatisfeitos'],
        legend: {
          position: 'bottom',
        },
        colors: ['#28a745', '#ffc107', '#dc3545'],
      },
    }
  },

  methods: {
    chartSeries(pergunta) {
      const series = pergunta.alternativas.map(
        (alternativa) => alternativa.resultado,
      )

      this.chartOptions.labels = pergunta.alternativas.map(
        (alternativa) => alternativa.descricao,
      )

      return series
    },
    chartSeriesSatisfacaoGeral() {
      const series = [
        this.satisfacaoGeral.data.satisfeitos.percentual,
        this.satisfacaoGeral.data.indiferentes.percentual,
        this.satisfacaoGeral.data.insatisfeitos.percentual,
      ]

      return series
    },
    onFiltered(filteredItems) {
      this.table.totalRows = filteredItems.length
      this.table.currentPage = 1
    },
    getBgVariant(valorNota) {
      // Mapear os valores de item.valor_nota para as classes de background desejadas
      if (valorNota <= 6) {
        return 'danger'
      }
      if (valorNota === 7 || valorNota === 8) {
        return 'warning'
      }
      if (valorNota === 9 || valorNota === 10) {
        return 'success'
      }
      return 'info'
    },
    async getRespostaPesquisaById(dados) {
      try {
        // this.loadDataEditing = true
        this.respostaPesquisaIndividual = await this.$store.dispatch(
          'getRespostasPesquisaById',
          dados.item.id,
        )
        this.nome = dados.item.nome
        this.empresa = dados.item.empresa
        this.data = dateSqlToDateBR(dados.item.data)
        console.log(this.respostaPesquisaIndividual)
        this.$bvModal.show('modalRespostaIndividual')
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
        }
        this.$emit('closeModal', false)
      }
    },
    async getDadosResultado() {
      this.loadData = true
      this.showDashboard = false
      try {
        if (
          this.tipoPesquisaId === '' ||
          this.dataFinal === '' ||
          this.dataInicio === ''
        ) {
          this.$root.$bvToast.toast(
            'Preencha todos os campos para buscar os resultados.',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
          this.loadData = false
        } else {
          const unidadesAux = []
          for (let i = 0; i < this.unidadesSelecionadas.length; i += 1) {
            unidadesAux.push(this.unidadesSelecionadas[i].id)
          }
          this.respostasAlternativas = await this.$store.dispatch(
            'getRespostasAlternativasPesquisa',
            {
              tipoPesquisaId: this.tipoPesquisaId,
              dataInicio: this.dataInicio,
              dataFinal: this.dataFinal,
              unidades: unidadesAux,
            },
          )
          this.respostasCsat = await this.$store.dispatch(
            'getRespostasCsatPesquisa',
            {
              tipoPesquisaId: this.tipoPesquisaId,
              dataInicio: this.dataInicio,
              dataFinal: this.dataFinal,
              unidades: unidadesAux,
            },
          )
          this.respostasNps = await this.$store.dispatch(
            'getRespostasNpsPesquisa',
            {
              tipoPesquisaId: this.tipoPesquisaId,
              dataInicio: this.dataInicio,
              dataFinal: this.dataFinal,
              unidades: unidadesAux,
            },
          )
          this.respostasParagrafo = await this.$store.dispatch(
            'getRespostasParagrafoPesquisa',
            {
              tipoPesquisaId: this.tipoPesquisaId,
              dataInicio: this.dataInicio,
              dataFinal: this.dataFinal,
              unidades: unidadesAux,
            },
          )
          this.respostasPesquisas = await this.$store.dispatch(
            'getRespostasTipoPesquisa',
            {
              tipoPesquisaId: this.tipoPesquisaId,
              dataInicio: this.dataInicio,
              dataFinal: this.dataFinal,
              unidades: unidadesAux,
            },
          )
          const resposta = await this.$store.dispatch(
            'getRSatisfacaoGeralPesquisa',
            {
              tipoPesquisaId: this.tipoPesquisaId,
              dataInicio: this.dataInicio,
              dataFinal: this.dataFinal,
              unidades: unidadesAux,
            },
          )
          this.satisfacaoGeral = resposta
          console.log(this.satisfacaoGeral)
          console.log(this.respostasParagrafo)
          console.log(this.respostasNps)
          console.log(this.respostasCsat)
          console.log(this.respostasAlternativas)
          console.log(this.respostasPesquisas)
          this.loadData = false
          this.showDashboard = true
          this.table.totalRows = this.respostasPesquisas.length
        }
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            variant: 'warning',
            solid: true,
          })
          this.loadData = false
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
          this.loadData = false
        }
      }
    },
    async getTipoPesquisa() {
      try {
        this.tipoPesquisa = await this.$store.dispatch(
          'getTipoPesquisa',
          this.tipoPesquisaId,
        )
        this.loadData = false
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
        }
      }
    },
    async getTiposPesquisas() {
      try {
        this.tiposPesquisas = await this.$store.dispatch('getTiposPesquisas')
        this.loadData = false
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
        }
      }
    },

    async getUnidades() {
      try {
        this.unidades = await this.$store.dispatch('getUnidades')
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
        }
      }
    },
  },

  async mounted() {
    this.getTiposPesquisas()
    this.getUnidades()
    const currentDate = new Date()
    this.dataFinal = moment(currentDate).format('YYYY-MM-DD')
    this.dataInicio = moment(
      currentDate.setMonth(currentDate.getMonth() - 1),
    ).format('YYYY-MM-DD')
  },
}
</script>

<style>
.offcanvas {
  position: fixed;
  top: 0;
  right: -250px;
  bottom: 0;
  width: 400px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  transition: right 0.3s ease-in-out;
  z-index: 9999;
}

.offcanvas.show {
  right: 0;
}
.barra-lateral-danger {
  border-left: 5px solid #dc3545; /* Cor da borda */
  padding-left: 10px; /* Espaçamento para afastar o conteúdo da borda */
}
.barra-lateral-warning {
  border-left: 5px solid #ffc107; /* Cor da borda */
  padding-left: 10px; /* Espaçamento para afastar o conteúdo da borda */
}
.barra-lateral-success {
  border-left: 5px solid #28a745; /* Cor da borda */
  padding-left: 10px; /* Espaçamento para afastar o conteúdo da borda */
}
.barra-lateral-success-2 {
  border-left: 5px solid #80c97e; /* Cor da borda */
  padding-left: 10px; /* Espaçamento para afastar o conteúdo da borda */
}
.abbreviate {
  max-width: 15ch;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
